import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class ProductOptionsStore {


    Token = null;
    stockAvailiable = false;
    unit_type_id = "";
    stock_qty = "";
    unit_id = "";
    ProductOptions = [];
    UnitDetails = [];
    ProductValues = [];
    name = "";
    namevalue = "";
    displaynamevalue="";
    display = "";
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errLoadProductOptions = "";
    LoadProductOptions = false;
    delateLoader = false;
    nolist = false;
    ProductOptionspopup = false;
    deletepopup = false;
    ProductOptionsname = "";
    status = "";
    is_display_in_filter = "";
    option_is_color = "";
    option_is_separate_images = "";
    statesid = "";
    ProductOptionsid = [];
    ProductOptionsValueid = [];
    seoUrl = "";
    statuspopup = false;
    addProductOptionsname = "";
    imageurl = "";
    logoimage = "";
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allProductOptions = [];
    alldeactivate = 'false';
    isBannerUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    ProductOptionsFormDetails = {
        "name": "",
        "seo_url": "",
        "logo_image": "",
        "banner_image": "",
        "is_active": ""
    };
    ProductValuesFormDetails = {
        "option_value_name": "",
        "display_name":"",
        "stock_unit": "",
        "unit_type_id": "",
        "display_order": "",
        "image": "",
        "stock_qty": "",
        "is_stock_avail": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addProductOptionsname: observable,
            status: observable,
            errorMessages: observable,
            setaddProductOptionsname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.optionvalues) {
            this.getProductValuesList(isloading.id);
            return
        }
        if (isloading.isloading) {
            this.getFilterProductOptions();

            //   this.getStateList();
            return
        }
        if (isloading.addloading) {
            //   this.getStateList();
        }
        if (isloading.ProductOptionseditloading) {
            this.getProductOptionsform(isloading.id);
            return
        }
        if (isloading.ProductValueseditloading) {
            this.getProductValuesform(isloading.id);
            this.getUnitDetails();

            return
        }
        if (isloading.addproductvalue) {
            this.getUnitDetails();
            return
        }





    }
    //All Clear 
    allclear = () => {
        this.SetfilterSearch("");
        this.getFilterProductOptions();
        //   this.status="";
        //   this.statesid="";
    }
    allclearoptionvalue = (id) => {
        this.filterSearch = ""
        this.getFilterOptionsValues(id)
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    setname = (text) => {
        this.name = text;
    };
    setnamevalue = (text) => {
        this.namevalue = text;
    };
    setdisplayvalue= (text) => {
        this.displaynamevalue = text;
    };
    setdisplay = (text) => {
        this.display = text;
    };
    setstatus = (text) => {
        this.status = !this.status
    };
    setis_display_in_filter = (text) => {
        this.is_display_in_filter = !this.is_display_in_filter
    };
    setoption_is_color = (text) => {
        this.option_is_color = !this.option_is_color
    };
    setoption_is_separate_images = (text) => {
        this.option_is_separate_images = !this.option_is_separate_images
    };

    getid = (text) => {
        this.ProductOptionsid.push(text)
    };

    getValueid = (text) => {
        this.ProductOptionsValueid.push(text)
    };


    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallProductOptions = (text) => {
        this.allProductOptions = text;
    }

    getunit_type_id = (text) => {
        this.unit_type_id = text
    }
    getunit_id = (text) => {
        this.unit_id = text
    }

    //get city name
    getProductOptionsname = (text) => {
        this.ProductOptionsname = text;
    };

    setaddProductOptionsname = (text) => {
        this.addProductOptionsname = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setProductOptionspopup = (e) => {
        this.ProductOptionspopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }

    setstockAvailiable = (text) => {
        this.stockAvailiable = !this.stockAvailiable;
    };
    setstock_qty = (text) => {
        this.stock_qty = text;
    };


    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the ProductOptions", text);
        this.logoimage = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the brand", text);
        this.logoimage = text;
    };
    getbanner_image = (text) => {
        console.log(text);
        this.bannerimage = text;
    };
    SetfilterSearch = (text) => {
        console.log("Color",text);
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisBannerUpdated = (text) => {
        this.isBannerUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };
    setProductOptionsFormDetails = (ProductOptionsDetail) => {
        this.ProductOptionsFormDetails = {
            ...this.ProductOptionsFormDetails,
            name: ProductOptionsDetail.name || "", // Use default value if name is undefined
            seo_url: ProductOptionsDetail.seo_url || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.ProductOptionsFormDetails.logo_image) ? this.logofile.length == 0 ? ProductOptionsDetail.logo_image    : this.ProductOptionsFormDetails.logo_image  : this.logofile.length !== 0 ? this.ProductOptionsFormDetails.logo_image :  ProductOptionsDetail.logo_image   || "",
            // banner_image: this.isBase64(ProductOptionsDetail.banner_image) ? this.ProductOptionsFormDetails.banner_image : ProductOptionsDetail.banner_image || "",
            logo_image: ProductOptionsDetail.logo_image || "",
            banner_image: ProductOptionsDetail.banner_image || "",
            is_active: ProductOptionsDetail.is_active
        };
    };
    setProductValuesFormDetails = (ProductValuesDetail) => {
        console.log("prodcucttttttttttttttttt", ProductValuesDetail);
        this.ProductValuesFormDetails = {
            ...this.ProductValuesFormDetails,
            option_value_name: ProductValuesDetail.option_value_name || "", // Use default value if name is undefined
            display_order: ProductValuesDetail.display_order || "", // Use default value if seo_url is undefined
            image: ProductValuesDetail.image || "",
            display_name: ProductValuesDetail.display_name || "",
            is_stock_avail: ProductValuesDetail.is_stock_avail || "",
            stock_qty: ProductValuesDetail.stock_qty || "",
            unit_type_id: ProductValuesDetail.unit_type_id || "",
            stock_unit: ProductValuesDetail.stock_unit || ""

        };
    };
    // setProductOptionsFormDetails = (text) => {
    //     this.ProductOptionsFormDetails = text;
    //     console.log("setProductOptionsFormDetails", this.ProductOptionsFormDetails.ProductOptionsname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }

    // get ProductOptions list
    getProductOptionsList = () => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.ProductOptions = [];
        });
        getMethodData(`/api/v1/admin/options/get-list?page=${this.PaginationPage}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductOptions = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    if (!error.response) {
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter League court

    getFilterProductOptions = () => {
        runInAction(() => {
            this.LoadProductOptions = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.getProductOptionsList = [];
        });
        postMethodData(`/api/v1/admin/options/search?page=${this.PaginationPage}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.LoadProductOptions = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.LoadProductOptions = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductOptions = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.LoadProductOptions = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // get ProductOptions list
    getProductValuesList = (id) => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.ProductValues = [];
        });
        getMethodData(`/api/v1/admin/options-values/get-list/${id}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductValues = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    if (!error.response) {
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter League court
    getFilterValuesOptions = (id) => {
        runInAction(() => {
            this.LoadProductOptions = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.getProductOptionsList = [];
        });
        getMethodData(`/api/v1/admin/options-values/get-list/${id}?page=${this.PaginationPage}`, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductValues = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    getFilterOptionsValues = (id) => {
        runInAction(() => {
            this.LoadProductOptions = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.getProductOptionsList = [];
        });
        postMethodData(`/api/v1/admin/options-values/search?page=${this.PaginationPage}`, {
            keyword: this.filterSearch,
            option_id: id
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductValues = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };





    // add new ProductOptions
    addProductOptions = () => {
        runInAction(() => {
            this.addloadProductOptions = true;
            this.errLoadProductOptions = "";
        });
        const formData = new FormData();

        formData.append('name', this.name);
        formData.append('status', this.status ? 1 : 0);
        formData.append('is_display_in_filter', this.is_display_in_filter ? 1 : 0);
        formData.append('option_is_color', this.option_is_color ? 1 : 0);
        formData.append('option_is_separate_images', this.option_is_separate_images ? 1 : 0);

        postMethodData("/api/v1/admin/options/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getFilterProductOptions();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadProductOptions = false;
                this.errLoadProductOptions = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadProductOptions = false;
                    if (!error.response) {
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    // add new ProductOptions
    addProductvalue = (id) => {
        runInAction(() => {
            this.addloadProductOptions = true;
            this.errLoadProductOptions = "";
        });
        const formData = new FormData();
        if (this.logoimage) {
            const Logobase64String = this.logoimage.split(',')[1];
            const logoFile = this.base64toFile(Logobase64String, 'logo.png', 'image/png');
            formData.append('image', logoFile);
        }
        else {
            formData.append('image', null);
        }
        formData.append('option_id', id);
        formData.append('name', this.namevalue);
        formData.append('display_name', this.displaynamevalue);

        if (this.stockAvailiable != 0) {
            formData.append('stock_unit', this.unit_id);
            formData.append('unit_type_name', this.unit_type_id);
            formData.append('stock_qty', this.stock_qty);

        }

        formData.append('is_stock_avail', this.stockAvailiable ? 1 : 0);
        formData.append('display_order', this.display);



        postMethodData("/api/v1/admin/options-values/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getFilterProductOptions();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadProductOptions = false;
                this.errLoadProductOptions = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadProductOptions = false;
                    if (!error.response) {
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Active and inactive
    statusChange = () => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.ProductOptionspopup = true;

            this.errLoadProductOptions = "";
        });
        postMethodData(`/api/v1/admin/ProductOptions/update-status`, {

            "ProductOptionsId": this.ProductOptionsid,
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsid = [];

                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;
                        this.ProductOptionsid = [];

                        this.errLoadProductOptions = resp?.data?.message;
                        this.getFilterProductOptions();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    this.ProductOptionspopup = false;

                    this.errLoadProductOptions = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductOptionsid = [];

                    if (!error.response) {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;

                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.ProductOptionsLoader = false;
                    this.ProductOptionspopup = false;

                    this.errLoadProductOptions = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    BulkstatusChange = (allProductOptions) => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.ProductOptionspopup = true;

            this.errLoadProductOptions = "";
        });

        postMethodData(`/api/v1/admin/ProductOptions/toggle-bulk-status`, {

            "branchId": JSON.stringify(allProductOptions),
            "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;

                        this.errLoadProductOptions = resp?.data?.message;
                        this.getFilterProductOptions();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    this.ProductOptionspopup = false;

                    this.errLoadProductOptions = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;

                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.ProductOptionsLoader = false;
                    this.ProductOptionspopup = false;

                    this.errLoadProductOptions = error?.response?.data?.message;
                })
                return;
            })
    };

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadProductOptions = "";
        });
        postMethodData(`/api/v1/admin/options/delete`, {

            "record_id": this.ProductOptionsid,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.ProductOptionsid = [];
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadProductOptions = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.ProductOptionsid = [];

                    this.errLoadProductOptions = resp?.data?.message;
                    this.getFilterProductOptions();
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadProductOptions = false;
                this.errLoadProductOptions = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    this.ProductOptionsid = [];

                    if (!error.response) {
                        this.LoadProductOptions = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadProductOptions = false;
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }

    deleteValuefunction = (id) => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadProductOptions = "";
        });
        postMethodData(`/api/v1/admin/options-values/delete`, {

            "record_id": this.ProductOptionsValueid,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.ProductOptionsValueid = [];
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadProductOptions = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.ProductOptionsValueid = [];

                    this.errLoadProductOptions = resp?.data?.message;
                    this.getProductValuesList(id);
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadProductOptions = false;
                this.errLoadProductOptions = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    this.ProductOptionsValueid = [];

                    if (!error.response) {
                        this.LoadProductOptions = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadProductOptions = false;
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }

    // Bulk Delete 
    BulkDelete = (allProductOptions) => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.ProductOptionspopup = true;

            this.errLoadProductOptions = "";
        });

        postMethodData(`/api/v1/admin/ProductOptions/multi-delete`, {

            "ProductOptionsId": JSON.stringify(allProductOptions),
            // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;

                        this.errLoadProductOptions = resp?.data?.message;
                        this.getFilterProductOptions();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    this.ProductOptionspopup = false;

                    this.errLoadProductOptions = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;

                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.ProductOptionsLoader = false;
                    this.ProductOptionspopup = false;

                    this.errLoadProductOptions = error?.response?.data?.message;
                })
                return;
            })
    };

    //get ProductOptions by id
    getProductOptionsform = (id) => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.ProductOptionspopup = true;

            this.errLoadProductOptions = "";
        });
        postMethodData(`/api/v1/admin/options/form`, {

            "option_id": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;
                        this.name = resp.data.data.optionDetail.option_name;
                        this.is_display_in_filter = resp.data.data.optionDetail.is_display_in_filter;
                        this.option_is_color = resp.data.data.optionDetail.option_is_color;
                        this.option_is_separate_images = resp.data.data.optionDetail.option_is_separate_images;
                        this.errLoadProductOptions = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    this.ProductOptionspopup = false;

                    this.errLoadProductOptions = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;

                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.ProductOptionsLoader = false;
                    this.ProductOptionspopup = false;

                    this.errLoadProductOptions = error?.response?.data?.message;
                })
                return;
            })
    };

    //get ProductValues by id
    getProductValuesform = (id) => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.ProductOptionspopup = true;

            this.errLoadProductOptions = "";
        });
        postMethodData(`/api/v1/admin/options-values/form`, {

            "record_id": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;
                        this.setProductValuesFormDetails(resp.data.data.optionValueDetail);
                        this.Base64logoImage = resp.data.data.optionValueDetail.image;

                    })
                    return;
                }
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    this.ProductOptionspopup = false;

                    this.errLoadProductOptions = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.ProductOptionsLoader = false;
                        this.ProductOptionspopup = false;

                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.ProductOptionsLoader = false;
                    this.ProductOptionspopup = false;

                    this.errLoadProductOptions = error?.response?.data?.message;
                })
                return;
            })
    };
    //update method
    EditPrductValues = (option_id, id) => {
        runInAction(() => {
            this.addloadProductOptions = true;
            this.errLoadProductOptions = "";
        });

        const formData = new FormData();
        if (this.ProductValuesFormDetails.image) {
            const Logobase64String = this.ProductValuesFormDetails.image?.split(',')[1];
            const logoFile = this.base64toFile(Logobase64String, 'logo.png', 'image/png');
            console.log(logoFile, this.ProductValuesFormDetails.image);
            logoFile ?
                formData.append('image', logoFile)
                :
                formData.append('image', this.ProductValuesFormDetails.image)

        }
        console.log("this.ProductValuesFormDetails.is_stock_avail", this.ProductValuesFormDetails.is_stock_avail);
        formData.append('option_id', option_id);
        formData.append('display_name', this.ProductValuesFormDetails.display_name);
        formData.append('record_id', id);
        formData.append('name', this.ProductValuesFormDetails.option_value_name);
        formData.append('display_order', this.ProductValuesFormDetails.display_order);
        if (this.ProductValuesFormDetails.is_stock_avail != 0) {
            formData.append('stock_qty', this.ProductValuesFormDetails.stock_qty);
            formData.append('stock_unit', this.ProductValuesFormDetails.stock_unit);
            formData.append('unit_type_name', this.ProductValuesFormDetails.unit_type_id);
        }


        formData.append('is_stock_avail', this.ProductValuesFormDetails.is_stock_avail == "" ? 0 : this.ProductValuesFormDetails.is_stock_avail);
        // formData.append('isBannerUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        postMethodData(`/api/v1/admin/options-values/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadProductOptions = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadProductOptions = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getProductOptionsList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadProductOptions = false;
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.addloadProductOptions = false;
                    this.errLoadProductOptions = error?.response?.data?.message;
                })
                return;
            })
    };


    //update method
    EditPrductMangment = (id) => {
        runInAction(() => {
            this.addloadProductOptions = true;
            this.errLoadProductOptions = "";
        });

        const formData = new FormData();


        formData.append('option_id', id);
        formData.append('name', this.name);
        formData.append('status', this.status ? 1 : 0);
        formData.append('is_display_in_filter', this.is_display_in_filter ? 1 : 0);
        formData.append('option_is_color', this.option_is_color ? 1 : 0);
        formData.append('option_is_separate_images', this.option_is_separate_images ? 1 : 0);
        // formData.append('isBannerUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        postMethodData(`/api/v1/admin/options/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getProductOptionsList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };

    //Get Unit Details
    getUnitDetails = () => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.ProductOptions = [];
        });
        getMethodData(`/api/v1/admin/unit-detail`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.UnitDetails = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    if (!error.response) {
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };


}




export const ProductOptionsStoreContext = createContext(new ProductOptionsStore());
export const useProductOptionsStore = () => useContext(ProductOptionsStoreContext);
