import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class CategoriesUseStore {

    Token = null;
    root_categories = [];
    rankList = [];
    Rank = "";
    meta_description = "";
    is_categoriesid = "";
    Catloader = false;
    Cat_identifiername = "";
    parentId = null;
    rating_categories_id = [];
    rating_type_detail = [];
    rating_type_detail_id = [];
    ratingList = [];
    All_categories = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errCategories = "";
    LoadCategories = false;
    CategoriesLoader = false;
    delateLoader = false;
    nolist = false;
    adminUserpopup = false;
    deletepopup = false;
    name = "";
    status = "false";
    statesid = "";
    category_id = "";
    seoUrl = "";
    statuspopup = false;
    addcatname = "";
    imageurl = "";
    logoimage = "";
    thumbimage = "";
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allbrand = [];
    alldeactivate = 'false';
    checkeddd = '';
    password = "";
    errPassword = "";
    confirmresetpas = "";
    username = "";
    email = "";
    publish = "false";
    passwordpopup = false;
    passwordLoading = false;
    AdminPermissions = [];
    publish = "";
    CategoriesFormDetails = {
        "category_name": "",
        "description": "",
        "image": "",
        "seo_url": "",
        "parent_id": "",
        "is_product": "",
        "rank":"",
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addcategoryname: observable,
            status: observable,
            errorMessages: observable,
            setaddcategoryname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            // this.getFilterAdminUser();
            this.getRootCategoriesList()
            //   this.getStateList();
            return
        }
        if (isloading.addcategory) {
            //   this.getStateList();
            this.getAllCategoriesList()
            this.getcategoryrankList()
            // this.getRateingList();
        }
        if (isloading.categorieseditloading) {
            this.getcategoriesform(isloading.id);
            this.getAllCategoriesList()
            this.getcategoryrankList()
            // this.getRateingList();
            return
        }
        if (isloading.AdminPermissions) {
            // this.getAdminPermissions(isloading.id);
            return
        }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this.getFilterAdminUser();
        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }
    setRank = (text) => {
        this.Rank = text
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.category_id = text
    };

    setaddcatname = (text) => {
        this.addcatname = text
    };
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallBrand = (text) => {
        this.allbrand = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = !this.status;
    };

    //get city name
    getcategoryname = (text) => {
        this.categoryname = text;
    };
    setCat_identifiername = (text) => {
        this.Cat_identifiername = text;
    };
    setemail = (text) => {
        this.email = text;
    };
    setname = (text) => {
        this.name = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setroot_categoriespopup = (e) => {
        this.adminUserpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    getusername = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.username = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the brand", text);
        this.logoimage = text;
    };
    getthumbimage = (text) => {
        console.log("checking in the brand", text);
        this.thumbimage = text;
    };
    getbanner_image = (text) => {
        console.log(text);
        this.bannerimage = text;
    };

    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    // get password value
    passwordChanged = (text) => {
        this.password = text
    };
    setmeta_description = (text) => {

        this.meta_description = text

    };
    Confirmpassw = (text) => {
        this.confirmresetpas = text
    };
    setpublish = (text) => {
        this.publish = !this.publish;
    };
    setparentId = (text) => {
        this.parentId = text

    };
    setpasswordpopup = () => {
        this.passwordpopup = !this.passwordpopup
        this.errPassword = ""
        // this.currentpassword = ""
        // this.newpassword = ""
        // this.confirmnewpassword = ""
    }

    setrating_categories_id = (text) => {
        this.rating_categories_id = [...text];
    }

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };
    setrating_type_detail = (text) => {
        this.rating_type_detail = [...text];
    }
    setrating_type_detail_id = (text) => {
        this.rating_type_detail_id = [...text];
    }
    setCategoriesFormDetails = (CategoriesDetail) => {
        this.CategoriesFormDetails = {
            ...this.CategoriesFormDetails,
            category_name: CategoriesDetail.category_name || "", // Use default value if name is undefined
            description: CategoriesDetail.description || "",
            seo_url: CategoriesDetail.seo_url || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.CategoriesFormDetails.logo_image) ? this.logofile.length == 0 ? CategoriesDetail.logo_image    : this.CategoriesFormDetails.logo_image  : this.logofile.length !== 0 ? this.CategoriesFormDetails.logo_image :  CategoriesDetail.logo_image   || "",
            // banner_image: this.isBase64(CategoriesDetail.banner_image) ? this.CategoriesFormDetails.banner_image : CategoriesDetail.banner_image || "",
            parent_id: CategoriesDetail.parent_id || "",
            is_product: CategoriesDetail.is_product || "",
            image: CategoriesDetail.image || "",
            rank: CategoriesDetail.rank


        };
    };
    // setCategoriesFormDetails = (text) => {
    //     this.CategoriesFormDetails = text;
    //     console.log("setCategoriesFormDetails", this.CategoriesFormDetails.categoryname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }
    // get root categories list
    getRootCategoriesList = () => {
        runInAction(() => {
            this.Catloader = true;
            this.errCategories = "";
            this.saved = false;
            this.nolist = false;
            this.root_categories = [];
        });
        getMethodData(`/api/v1/admin/product-categories/get-list`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.Catloader = false;
                        this.errCategories
                            = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.Catloader = false;
                        this.errCategories
                            = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.root_categories = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadCategories = false;
                    this.errCategories
                        = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.Catloader = false;
                    if (!error.response) {
                        this.errCategories
                            = error?.message;
                        toast.error(error?.message);
                    }
                    this.errCategories
                        = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    getRateingList = () => {
        runInAction(() => {
            this.CategoriesLoader = true;
            this.errCategories = "";
            this.saved = false;
            this.nolist = false;
            this.ratingList = [];
        });
        getMethodData(`/api/v1/admin/rating-type/auto-complete`, this.Token)
            .then((resp) => {
                console.log("ratinglist", resp);
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                        this.errCategories
                            = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                        this.errCategories
                            = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ratingList = resp?.data?.data;
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadCategories = false;
                    this.errCategories
                        = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.CategoriesLoader = false;
                    if (!error.response) {
                        this.errCategories
                            = error?.message;
                        toast.error(error?.message);
                    }
                    this.errCategories
                        = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    // get root categories list
    getAllCategoriesList = () => {
        runInAction(() => {
            this.CategoriesLoader = true;
            this.errCategories = "";
            this.saved = false;
            this.nolist = false;
            this.All_categories = [];
        });
        getMethodData(`/api/v1/admin/product-categories/get-list/dropdown`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                        this.errCategories
                            = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                        this.errCategories
                            = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.All_categories = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadCategories = false;
                    this.errCategories
                        = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.CategoriesLoader = false;
                    if (!error.response) {
                        this.errCategories
                            = error?.message;
                        toast.error(error?.message);
                    }
                    this.errCategories
                        = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    getcategoryrankList = () => {
        runInAction(() => {
            this.CategoriesLoader = true;
            this.errCategories = "";
            this.saved = false;
            this.nolist = false;
        });
        getMethodData(`/api/v1/admin/product-category-rank`, this.Token)
            .then((resp) => {
             
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                        this.errCategories
                            = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                    
                        this.rankList = resp?.data?.data;
                        console.log("rankkkkkkkkkkkkkkkkkk",this.rankList);
               
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadCategories = false;
                    this.errCategories
                        = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.CategoriesLoader = false;
                    if (!error.response) {
                        this.errCategories
                            = error?.message;
                        toast.error(error?.message);
                    }
                    this.errCategories
                        = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter League court

    getFilterAdminUser = () => {
        runInAction(() => {
            this.LoadCategories = true;
            this.errCategories
                = "";
            this.saved = false;
            this.nolist = false;
            this.getAdminUserList = [];
        });
        postMethodData(`/api/v1/admin/admin-users/search?page=${this.PaginationPage + 1}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.AdminUsers = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // add new city
    addUser = () => {
        if (this.password != this.confirmresetpas) {
            runInAction(() => {
                toast.error("The password and confirmation password do not match.")
            })
            return;
        }
        runInAction(() => {
            this.addloadCategoriesLoadCategories = true;
            this.errCategories
                = "";
        });
        const formData = new FormData();

        formData.append('fullname', this.name);



        formData.append('username', this.username);

        formData.append('email', this.email);


        formData.append('password', this.password);

        formData.append('confirmPassword', this.confirmresetpas);
        formData.append('status', this.status);
        formData.append('alert', this.alert ? 0 : 1);




        postMethodData("/api/v1/admin/admin-users/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadCategoriesLoadCategories = false;
                    this.errCategories
                        = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadCategoriesLoadCategories = false;
                    this.errCategories
                        = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getFilterAdminUser();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadCategoriesLoadCategories = false;
                this.errCategories
                    = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadCategoriesLoadCategories = false;
                    if (!error.response) {
                        this.errCategories
                            = error?.message;
                        toast.error(error?.message);
                    }
                    this.errCategories
                        = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Active and inactive
    statusChange = (id, status) => {
        runInAction(() => {
            this.CategoriesLoader = true;
            this.adminUserpopup = true;

            this.errCategories
                = "";
        });
        postMethodData(`/api/v1/admin/product-categories/update-status`, {
            "status": status,
            "categoryId": id
        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                        this.adminUserpopup = false;
                        this.errCategories
                            = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                        this.adminUserpopup = false;
                        this.errCategories
                            = resp?.data?.message;
                        this.getRootCategoriesList();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.CategoriesLoader = false;
                    this.adminUserpopup = false;

                    this.errCategories
                        = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.CategoriesLoader = false;
                        this.adminUserpopup = false;

                        this.errCategories
                            = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.CategoriesLoader = false;
                    this.adminUserpopup = false;

                    this.errCategories
                        = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    BulkstatusChange = (allbrand) => {
        runInAction(() => {
            this.CategoriesLoader = true;
            this.adminUserpopup = true;

            this.errCategories
                = "";
        });

        postMethodData(`/api/v1/admin/admin-users/update-status`, {


            "recordId": JSON.stringify(allbrand),
            "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {

          
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                        this.adminUserpopup = false;
                        this.errCategories
                            = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                        this.adminUserpopup = false;
                    
                        this.errCategories
                            = resp?.data?.message;
                        this.getFilterAdminUser();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.CategoriesLoader = false;
                    this.adminUserpopup = false;

                    this.errCategories
                        = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.CategoriesLoader = false;
                        this.adminUserpopup = false;

                        this.errCategories
                            = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.CategoriesLoader = false;
                    this.adminUserpopup = false;

                    this.errCategories
                        = error?.response?.data?.message;
                })
                return;
            })
    };

    // add new categories
    addcategories = () => {


        runInAction(() => {
            this.LoadCategories = true;
            this.errCategories = "";
        });
        const formData = new FormData();
        if (this.logoimage) {
            const Logobase64String = this.logoimage.split(',')[1];
            const logoFile = this.base64toFile(Logobase64String, 'logo.png', 'image/png');
            formData.append('image', logoFile);
        }
        else {
            formData.append('image', null);
        }
        // if (this.bannerimage) {
        //     const Bannerbase64String = this.bannerimage.split(',')[1];
        //     const BannerFile = this.base64toFile(Bannerbase64String, 'logo.png', 'image/png');
        //     formData.append('banner_image', BannerFile);
        // }
        // else {
        //     formData.append('banner_image', null);
        // }
        // if (this.bannerimage) {
        //     const Bannerbase64String = this.thumbimage.split(',')[1];
        //     const BannerFile = this.base64toFile(Bannerbase64String, 'logo.png', 'image/png');
        //     formData.append('thumb_image', BannerFile);
        // }
        // else {
        //     formData.append('thumb_image', null);
        // }


        formData.append('name', this.addcatname);
        formData.append('description', this.meta_description);

        formData.append('seo_url', `${SEO_URL}${this.seoUrl}`);
        if( this.Rank){
            formData.append('rank', this.Rank);
        }
     
        // formData.append('identifier', this.Cat_identifiername);

        // formData.append('rating_categories_id', JSON.stringify(this.rating_categories_id));
        formData.append('is_product', this.publish ? 1 : 0);
        {
            this.parentId !== null &&
                formData.append('parentId', this.parentId);

        }




        postMethodData("/api/v1/admin/product-categories/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadCategories = false;
                    this.errCategories = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadCategories = false;
                    this.errCategories = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.is_categoriesid = resp?.data?.categoryId;
                    this.getRootCategoriesList();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.LoadCategories = false;
                this.errCategories = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.LoadCategories = false;
                    if (!error.response) {
                        this.errCategories = error?.message;
                        toast.error(error?.message);
                    }
                    const formErrors = error.response.data.formErrors;
                    ( formErrors &&
                        Object.keys(formErrors)?.forEach((key) => {
                            const errorMessage = formErrors[key];
                            toast.error(` ${errorMessage}`, {
                                autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                            });
                        }))
                    this.errCategories = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //get Brand by id
    getcategoriesform = (id) => {
        runInAction(() => {
            this.CategoriesLoader = true;
            this.errCategories = "";
        });
        postMethodData(`/api/v1/admin/product-categories/form`, {

            "categoryId": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                        ;
                        this.errCategories = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        console.log("categorymanagment", resp);
                        this.setCategoriesFormDetails(resp.data.data.categoryDetail);
                        // this.setrating_type_detail(resp.data.data.rating_type_detail);
                        // this.Base64BannerImage = resp.data.data.categoryDetail.banner_image;
                        this.Base64logoImage = resp.data.data.categoryDetail.image;
                        this.CategoriesLoader = false;
                        this.publish = resp.data.data.categoryDetail.is_product;
                        this.categoryname = resp.data.data.categoryDetail.category_name;
                        this.errCategories = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.CategoriesLoader = false;
                    ;
                    this.errCategories = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.CategoriesLoader = false;
                        ;
                        this.errCategories = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.CategoriesLoader = false;
                    ;
                    this.errCategories = error?.response?.data?.message;
                })
                return;
            })
    };


    //update method
    updateCategories = (id) => {
        runInAction(() => {
            this.LoadCategories = true;
            this.errCategories = "";
        });
        const formData = new FormData();
        if (this.CategoriesFormDetails.image) {
            const Logobase64String = this.CategoriesFormDetails.image?.split(',')[1];
            const logoFile = this.base64toFile(Logobase64String, 'logo.png', 'image/png');
            console.log(logoFile, this.CategoriesFormDetails.image);
            logoFile ?
                formData.append('image', logoFile)
                :
                formData.append('image', this.CategoriesFormDetails.image)

        }
        // if (this.CategoriesFormDetails.thumb_image) {
        //     const Logobase64String = this.CategoriesFormDetails.logo_image?.split(',')[1];
        //     const logoFile = this.base64toFile(Logobase64String, 'logo.png', 'image/png');
        //     formData.append('thumbImage', logoFile);
        // }



        // if (this.CategoriesFormDetails.banner_image) {
        //     const Bannerbase64String = this.CategoriesFormDetails.banner_image?.split(',')[1];
        //     const BannerFile = this.base64toFile(Bannerbase64String, 'logo.png', 'image/png');
        //     formData.append('banner_image', BannerFile);
        // }


        if (this.crop.dimensions?.height) {
            formData.append('logo_height', this.crop.dimensions?.height);
        }


        if (this.crop.dimensions?.width) {
            formData.append('logo_width', this.crop.dimensions?.width);
        }

        if (this.BannerDimiensions?.dimensions?.height) {
            formData.append('banner_height', this.BannerDimiensions?.dimensions?.height);
        }


        if (this.BannerDimiensions?.dimensions?.width) {
            formData.append('banner_width', this.BannerDimiensions?.dimensions?.width);
        }

        formData.append('categoryId', id);
        formData.append('description', this.CategoriesFormDetails.description);

        formData.append('name', this.CategoriesFormDetails.category_name);
        formData.append('seo_url', this.CategoriesFormDetails.seo_url);
        {
            this.CategoriesFormDetails.parent_id != "" &&
                formData.append('parentId', this.CategoriesFormDetails.parent_id);

        }
        formData.append('is_product', this.CategoriesFormDetails.is_product || this.publish);
        
        if( this.CategoriesFormDetails.rank || this.Rank){
            formData.append('rank', this.CategoriesFormDetails.rank || this.Rank);
        }
      




        // formData.append('isBannerUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        putMethod(`/api/v1/admin/product-categories/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getRootCategoriesList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                   
                    const formErrors = error.response.data.formErrors;
                    ( formErrors &&
                        Object.keys(formErrors)?.forEach((key) => {
                            const errorMessage = formErrors[key];
                            toast.error(` ${errorMessage}`, {
                                autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                            });
                        }))
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };



    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errCategories
                = "";
        });
        postMethodData(`/api/v1/admin/product-categories/delete`, {

            "categoryId": this.category_id

        }, this.Token).then((resp) => {

            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadCategories = false;
                    this.setdelateLoader(false);
                    this.deletepopup = true;
                    this.errCategories
                        = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadCategories = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errCategories
                        = resp?.data?.message;
                    this.getRootCategoriesList();
                    toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadCategories = false;
                this.errCategories
                    = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    this.setdelateLoader(false);

                    if (!error.response) {
                        this.LoadCategories = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errCategories
                            = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadCategories = false;

                    this.errCategories
                        = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }




    //Admin Permissions List 

    getAdminPermissions = (id) => {
        runInAction(() => {
            this.CategoriesLoader = true;
            this.adminUserpopup = true;

            this.errCategories
                = "";
        });
        getMethodData(`/api/v1/admin/admin-permissions/list/${id}`,
            this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                        this.adminUserpopup = false;
                        this.errCategories
                            = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.AdminPermissions = resp?.data?.data?.permissionList;
                        this.CategoriesLoader = false;
                        this.adminUserpopup = false;
                        this.errCategories
                            = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.CategoriesLoader = false;
                    this.adminUserpopup = false;

                    this.errCategories
                        = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.CategoriesLoader = false;
                        this.adminUserpopup = false;

                        this.errCategories
                            = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.CategoriesLoader = false;
                    this.adminUserpopup = false;

                    this.errCategories
                        = error?.response?.data?.message;
                })
                return;
            })
    };

}





export const CategoriesStoreContext = createContext(new CategoriesUseStore());
export const useCategoriesStore = () => useContext(CategoriesStoreContext);
