import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class OrderStore {

    Token = null;
    Order = [];
    ReturnOrder =[];
    Ordersummary = "";
    order_status = [];
    OrderStatusLoader = false;
    dimensionspopup = false;
    billing_addr = "";
    savedfun = false;
    DuplicateProduct = "";
    Productlist = [];
    OrderProduct = [];
    filterSearch = "";
    PaginationPage = "1";
    TotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errLoadOrder = "";
    LoadOrder = false;
    delateLoader = false;
    nolist = false;
    ordernumberlist = false;
    Orderpopup = false;
    deletepopup = false;
    Ordername = "";
    status = "1";
    statesid = "";
    Orderid = [];
    seoUrl = "";
    statuspopup = false;
    addOrdername = "";
    imageurl = "";
    logoimage = "";
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allOrder = [];
    alldeactivate = 'false';
    isBannerUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    OrderFormDetails = {
        "name": "",
        "seo_url": "",
        "logo_image": "",
        "banner_image": "",
        "is_active": ""
    };
    saved = false;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            addOrdername: observable,
            status: observable,
            errorMessages: observable,
            setaddOrdername: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        
        if (isloading.isloading) {
            this.getFilterOrder();
            this.getOrder_status_List();
   
            //   this.getStateList();
            return
        }
        if (isloading.isReturn) {
    
            this.getReturnProductList();
            //   this.getStateList();
            return
        }
        if (isloading.Productlist) {
            this.getProductList(isloading.id);
            this.getProductCatalogList()
            return
        }
        if (isloading.addloading) {
            //   this.getStateList();
        }
        if (isloading.OrderDetail) {
            this.getProductOrderList(isloading.id);
     
            return
        
        }
        if (isloading.OrderDetailmodified) {
            this.getReturnOrderList(isloading.modified)
            return
        
        }

        if (isloading.Ordereditloading) {
            this.getOrderform(isloading.id);
            return
        }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this.getFilterOrder();

        //   this.status="";
        //   this.statesid="";
    }
    setimage = (text, type) => {
        this.imageurl = URL.createObjectURL(text[0]);
    }

    setalldeactivate = (text) => {
        console.log(text);
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.Orderid.push(text);
    };


    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallOrder = (text) => {
        this.allOrder = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
    };

    //get city name
    getOrdername = (text) => {
        this.Ordername = text;
    };

    setaddOrdername = (text) => {
        this.addOrdername = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
        console.log(this.logofile);
    };

    setOrderpopup = (e) => {
        this.Orderpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdimensionspopup = (e) => {
        this.dimensionspopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }
    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("checking in the Order", text);
        this.logoimage = text;
    };
    getbanner_image = (text) => {
        console.log(text);
        this.bannerimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisBannerUpdated = (text) => {
        this.isBannerUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };
    setDuplicateProduct = (text) => {
        console.log(text);
        this.DuplicateProduct = text;
    };

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };
    setOrderFormDetails = (OrderDetail) => {
        this.OrderFormDetails = {
            ...this.OrderFormDetails,
            name: OrderDetail.name || "", // Use default value if name is undefined
            is_active: OrderDetail.is_active
        };
    };
    // setOrderFormDetails = (text) => {
    //     this.OrderFormDetails = text;
    //     console.log("setOrderFormDetails", this.OrderFormDetails.Ordername);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }

    }



    // get Order list
    getOrderList = () => {
        runInAction(() => {
            this.OrderLoader = true;
            this.errLoadOrder = "";
            this.saved = false;
            this.nolist = false;
        });
        getMethodData(`/api/v1/admin/order/get-list?page=${this.PaginationPage}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.OrderLoader = false;
                        this.errLoadOrder = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.OrderLoader = false;
                        this.errLoadOrder = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Order = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadOrder = false;
                    this.errLoadOrder = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.OrderLoader = false;
                    if (!error.response) {
                        this.errLoadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    //Filter city
    // filter League court

    getFilterOrder = () => {
        runInAction(() => {
            this.OrderLoader = true;
            this.LoadOrder = true;
            this.errLoadOrder = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/order/search?page=${this.PaginationPage}`, {
            keyword: this.filterSearch
        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.OrderLoader = false;

                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.OrderLoader = false;
                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Order = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    this.OrderLoader = false;
                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message);
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Product list 
    // get Order list
    getProductList = (id) => {
        runInAction(() => {
            this.OrderLoader = true;
            this.errLoadOrder = "";
            this.saved = false;
            this.nolist = false;
        });
        postMethodData(`/api/v1/admin/Orders/get-list-mapping`,

            {
                "Order_id": id
            }
            , this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.OrderLoader = false;
                        this.errLoadOrder = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    console.log(resp?.data?.data.length);
                    runInAction(() => {
                        this.OrderLoader = false;
                        this.errLoadOrder = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                            console.log("nolist === true");
                        } else {
                            console.log("nolist === false");
                            this.nolist = false;
                        }
                        this.Productlist = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadOrder = false;
                    this.errLoadOrder = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.OrderLoader = false;
                    if (!error.response) {
                        this.errLoadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    getReturnProductList = () => {
        runInAction(() => {
            this.OrderLoader = true;
            this.errLoadOrder = "";
            this.saved = false;
            this.nolist = false;
        });
        getMethodData(`/api/v1/admin/order/return-order-list?page=${this.PaginationPage}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.OrderLoader = false;
                        this.errLoadOrder = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.OrderLoader = false;
                        this.errLoadOrder = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ReturnOrder = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadOrder = false;
                    this.errLoadOrder = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.OrderLoader = false;
                    if (!error.response) {
                        this.errLoadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    getProductOrderList = (id) => {
        runInAction(() => {
            this.ProductOrderLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.ordernumberlist = false;
            this.OrderProduct = [];

        });
        getMethodData(`/api/v1/admin/order/view/${id}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOrderLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }

                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOrderLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.ordernumberlist = true;
                            console.log("ordernumberlist === true");
                        } else {
                            console.log("ordernumberlist === false");
                            this.ordernumberlist = false;
                        }
                        this.OrderProduct = resp?.data?.data?.product_detail;
                        this.Ordersummary = resp?.data?.data?.order_detail;
                        this.billing_addr = resp?.data?.data?.addresses.billing_addr;
                        this.shipping_addr = resp?.data?.data?.addresses.shipping_addr;


                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductOrderLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    
    getReturnOrderList = (id) => {
        runInAction(() => {
            this.ProductOrderLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.ordernumberlist = false;
            this.OrderProduct = [];

        });
        getMethodData(`/api/v1/admin/order/return-order/${id}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOrderLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }

                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOrderLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.ordernumberlist = true;
                            console.log("ordernumberlist === true");
                        } else {
                            console.log("ordernumberlist === false");
                            this.ordernumberlist = false;
                        }
                        this.OrderProduct = resp?.data?.data?.product_detail;
                        this.Ordersummary = resp?.data?.data?.order_detail;
                        this.billing_addr = resp?.data?.data?.addresses.billing_addr;
                        this.shipping_addr = resp?.data?.data?.addresses.shipping_addr;


                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductOrderLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    getOrder_status_List = () => {
        runInAction(() => {
            this.OrderStatusLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.ordernumberlist = false;
            this.OrderProduct = [];

        });
        getMethodData(`/api/v1/admin/order-status/get-list`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.OrderStatusLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }

                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.OrderStatusLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.ordernumberlist = true;
                            console.log("ordernumberlist === true");
                        } else {
                            console.log("ordernumberlist === false");
                            this.ordernumberlist = false;
                        }
                        console.log("respppppppppp", resp.data);
                        this.order_status = resp?.data?.data;

                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.OrderStatusLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    // add new Order
    addOrder = () => {
        runInAction(() => {
            this.addloadOrder = true;
            this.errLoadOrder = "";
        });
        const formData = new FormData();

        formData.append('Order_name', this.addOrdername);
        formData.append('status', this.status);



        postMethodData("/api/v1/admin/Orders/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadOrder = false;
                    this.errLoadOrder = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadOrder = false;
                    this.errLoadOrder = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getFilterOrder();
                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadOrder = false;
                this.errLoadOrder = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadOrder = false;
                    if (!error.response) {
                        this.errLoadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //Active and inactive
    statusChange = () => {
        runInAction(() => {
            this.OrderLoader = true;
            this.Orderpopup = true;

            this.errLoadOrder = "";
        });
        postMethodData(`/api/v1/admin/Orders/update-status`, {

            "record_id": this.Orderid,
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.OrderLoader = false;
                        this.Orderpopup = false;
                        this.errLoadOrder = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.OrderLoader = false;
                        this.Orderpopup = false;
                        this.errLoadOrder = resp?.data?.message;
                        this.getFilterOrder();
                        this.Orderid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.OrderLoader = false;
                    this.Orderpopup = false;

                    this.errLoadOrder = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.OrderLoader = false;
                        this.Orderpopup = false;
                        this.Orderid = [];
                        this.errLoadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.OrderLoader = false;
                    this.Orderpopup = false;

                    this.errLoadOrder = error?.response?.data?.message;
                })
                return;
            })
    };
    //order status
    Order_status_Change = (id, status, weight, breadth, height, length) => {
        runInAction(() => {
            this.OrderStatusLoader = true;
            this.Orderpopup = true;

            this.errLoadOrder = "";
        });
        console.log("stattttttttttttttudssssssssssssssssssss", id, status);
        postMethodData(`/api/v1/admin/order/change-status`, {

            "order_id": status,
            "order_status": id,
            "length": length,
            "height": height,
            "breadth": breadth,
            "weight": weight

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.OrderStatusLoader = false;
                        this.Orderpopup = false;
                        this.errLoadOrder = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.OrderStatusLoader = false;
                        this.Orderpopup = false;

                        this.setdimensionspopup(false); // Close the modal after form submission
                        this.errLoadOrder = resp?.data?.message;
                        this.getFilterOrder();
                        this.Orderid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.OrderStatusLoader = false;
                    this.Orderpopup = false;

                    this.errLoadOrder = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.OrderStatusLoader = false;
                        this.Orderpopup = false;
                        this.Orderid = [];
                        this.errLoadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.OrderStatusLoader = false;
                    this.Orderpopup = false;

                    this.errLoadOrder = error?.response?.data?.message;
                })
                return;
            })
    };
   

    Rejected_status_Change = (id, status) => {
        runInAction(() => {
            this.OrderStatusLoader = true;
            this.Orderpopup = true;

            this.errLoadOrder = "";
        });
        console.log("stattttttttttttttudssssssssssssssssssss", id, status);
        putMethod(`/api/v1/admin/order/return-rejected`, {

            "return_id": status,
       

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.OrderStatusLoader = false;
                        this.Orderpopup = false;
                        this.errLoadOrder = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.OrderStatusLoader = false;
                        this.Orderpopup = false;

                        this.setdimensionspopup(false); // Close the modal after form submission
                        this.errLoadOrder = resp?.data?.message;
                        this.getReturnProductList();
                        this.Orderid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.OrderStatusLoader = false;
                    this.Orderpopup = false;

                    this.errLoadOrder = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.OrderStatusLoader = false;
                        this.Orderpopup = false;
                        this.Orderid = [];
                        this.errLoadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.OrderStatusLoader = false;
                    this.Orderpopup = false;

                    this.errLoadOrder = error?.response?.data?.message;
                })
                return;
            })
    };

    TimeStamp_Date = (id, date) => {
        runInAction(() => {
            this.OrderStatusLoader = true;
            this.Orderpopup = true;

            this.errLoadOrder = "";
        });

        putMethod(`/api/v1/admin/order/return-pickup`, {

            "return_id": id,
            "pickup_date": date
       

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.OrderStatusLoader = false;
                        this.Orderpopup = false;
                        this.errLoadOrder = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.OrderStatusLoader = false;
                        this.Orderpopup = false;

                        this.errLoadOrder = resp?.data?.message;
                        this.getReturnOrderList(id);
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.OrderStatusLoader = false;
                    this.Orderpopup = false;

                    this.errLoadOrder = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.OrderStatusLoader = false;
                        this.Orderpopup = false;
                        this.Orderid = [];
                        this.errLoadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.OrderStatusLoader = false;
                    this.Orderpopup = false;

                    this.errLoadOrder = error?.response?.data?.message;
                })
                return;
            })
    };



    Return_status_Change = (id, status, weight, breadth, height, length) => {
        runInAction(() => {
            this.OrderStatusLoader = true;
            this.Orderpopup = true;

            this.errLoadOrder = "";
        });
        console.log("stattttttttttttttudssssssssssssssssssss", id, status);
        putMethod(`/api/v1/admin/order/return-approved`, {

            "return_id": status,
            "length": length,
            "height": height,
            "breadth": breadth,
            "weight": weight

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.OrderStatusLoader = false;
                        this.Orderpopup = false;
                        this.errLoadOrder = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.OrderStatusLoader = false;
                        this.Orderpopup = false;

                        this.setdimensionspopup(false); // Close the modal after form submission
                        this.errLoadOrder = resp?.data?.message;
                        this.getReturnProductList();
                        this.Orderid = [];
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.OrderStatusLoader = false;
                    this.Orderpopup = false;

                    this.errLoadOrder = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.OrderStatusLoader = false;
                        this.Orderpopup = false;
                        this.Orderid = [];
                        this.errLoadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.OrderStatusLoader = false;
                    this.Orderpopup = false;

                    this.errLoadOrder = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    // BulkstatusChange = (allOrder) => {
    //     runInAction(() => {
    //         this.OrderLoader = true;
    //         this.Orderpopup = true;

    //         this.errLoadOrder = "";
    //     });

    //     postMethodData(`/api/v1/admin/Order/toggle-bulk-status`, {

    //         "branchId": JSON.stringify(allOrder),
    //         "status": JSON.stringify(this.alldeactivate ? 1 : 0)

    //     }, this.Token)
    //         .then((resp) => {
    //             if (resp?.data?.status === false) {
    //                 runInAction(() => {
    //                     this.OrderLoader = false;
    //                     this.Orderpopup = false;
    //                     this.errLoadOrder = resp?.data?.message;
    //                     toast.error(resp?.data?.message);
    //                 });
    //                 return;
    //             }
    //             if (resp?.data?.status === true) {
    //                 runInAction(() => {
    //                     this.OrderLoader = false;
    //                     this.Orderpopup = false;

    //                     this.errLoadOrder = resp?.data?.message;
    //                     this.getFilterOrder();
    //                     toast.success(resp?.data?.message);
    //                 })
    //                 return;
    //             }
    //             runInAction(() => {
    //                 this.OrderLoader = false;
    //                 this.Orderpopup = false;

    //                 this.errLoadOrder = resp?.data?.message;
    //             })
    //         })
    //         .catch((error) => {
    //             runInAction(() => {
    //                 if (!error.response) {
    //                     this.OrderLoader = false;
    //                     this.Orderpopup = false;

    //                     this.errLoadOrder = error?.message;
    //                     toast.error(error?.message);
    //                 }
    //                 toast.error(error?.response?.data?.message);
    //                 this.OrderLoader = false;
    //                 this.Orderpopup = false;

    //                 this.errLoadOrder = error?.response?.data?.message;
    //             })
    //             return;
    //         })
    // };

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadOrder = "";
        });
        postMethodData(`/api/v1/admin/order/delete`, {

            "record_id": this.Orderid,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadOrder = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadOrder = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadOrder = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadOrder = resp?.data?.message;
                    this.getFilterOrder();
                    this.Orderid = [];

                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadOrder = false;
                this.errLoadOrder = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadOrder = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadOrder = false;
                    this.errLoadOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // // Bulk Delete 
    // BulkDelete = (allOrder) => {
    //     runInAction(() => {
    //         this.OrderLoader = true;
    //         this.Orderpopup = true;

    //         this.errLoadOrder = "";
    //     });

    //     postMethodData(`/api/v1/admin/Order/multi-delete`, {

    //         "OrderId": JSON.stringify(allOrder),
    //         // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

    //     }, this.Token)
    //         .then((resp) => {
    //             if (resp?.data?.status === false) {
    //                 runInAction(() => {
    //                     this.OrderLoader = false;
    //                     this.Orderpopup = false;
    //                     this.errLoadOrder = resp?.data?.message;
    //                     toast.error(resp?.data?.message);
    //                 });
    //                 return;
    //             }
    //             if (resp?.data?.status === true) {
    //                 runInAction(() => {
    //                     this.OrderLoader = false;
    //                     this.Orderpopup = false;

    //                     this.errLoadOrder = resp?.data?.message;
    //                     this.getFilterOrder();
    //                     toast.success(resp?.data?.message);
    //                 })
    //                 return;
    //             }
    //             runInAction(() => {
    //                 this.OrderLoader = false;
    //                 this.Orderpopup = false;

    //                 this.errLoadOrder = resp?.data?.message;
    //             })
    //         })
    //         .catch((error) => {
    //             runInAction(() => {
    //                 if (!error.response) {
    //                     this.OrderLoader = false;
    //                     this.Orderpopup = false;

    //                     this.errLoadOrder = error?.message;
    //                     toast.error(error?.message);
    //                 }
    //                 toast.error(error?.response?.data?.message);
    //                 this.OrderLoader = false;
    //                 this.Orderpopup = false;

    //                 this.errLoadOrder = error?.response?.data?.message;
    //             })
    //             return;
    //         })
    // };


    //get Order by id
    getOrderform = (id) => {
        runInAction(() => {
            this.OrderLoader = true;
            this.Orderpopup = true;

            this.errLoadOrder = "";
        });
        postMethodData(`/api/v1/admin/Orders/form`, {

            "record_id": id,
            // "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.OrderLoader = false;
                        this.Orderpopup = false;
                        this.errLoadOrder = resp?.data?.message;
                        // toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.setOrderFormDetails(resp.data.data.OrderDetail);
                        this.OrderLoader = false;
                        this.Orderpopup = false;
                        this.errLoadOrder = resp?.data?.message;
                    })
                    return;
                }
                runInAction(() => {
                    this.OrderLoader = false;
                    this.Orderpopup = false;

                    this.errLoadOrder = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.OrderLoader = false;
                        this.Orderpopup = false;

                        this.errLoadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.OrderLoader = false;
                    this.Orderpopup = false;

                    this.errLoadOrder = error?.response?.data?.message;
                })
                return;
            })
    };



    //update method
    updateOrder = (id) => {
        runInAction(() => {
            this.addloadOrder = true;
            this.errLoadOrder = "";
        });
        const formData = new FormData();


        formData.append('record_id', id);
        formData.append('Order_name', this.OrderFormDetails.name);
        formData.append('is_active', this.OrderFormDetails.is_active);
        // formData.append('isBannerUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        putMethod(`/api/v1/admin/Orders/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getOrderList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };


    // add new Order
    addProductOrder = (Order_id, product_id) => {
        return new Promise((resolve, reject) => {
            runInAction(() => {
                this.OrderLoader = true;
                this.savedfun = false;
                this.Productlist = [];
                this.errLoadOrder = "";
            });

            const formData = new FormData();

            formData.append('Order_id', Order_id);
            formData.append('prod_id', product_id);

            postMethodData("/api/v1/admin/Orders/add-Order-product", formData, this.Token)
                .then((resp) => {
                    this.savedfun = true;

                    if (resp?.data?.status === false) {
                        runInAction(() => {
                            this.OrderLoader = false;
                            this.Productlist = [];
                            this.errLoadOrder = resp?.data?.message;
                            toast.error(resp.data.message);
                            reject(new Error(resp?.data?.message));
                        });
                    } else if (resp?.data?.status === true) {
                        runInAction(() => {
                            this.OrderLoader = false;
                            this.errLoadOrder = resp?.data?.message;
                            toast.success(resp.data.message);
                            this.getProductList(Order_id);
                            this.setDuplicateProduct(true);
                            this.savedfun = true;
                            resolve();
                        });
                    } else {
                        runInAction(() => {
                            this.OrderLoader = false;
                            this.errLoadOrder = resp?.data?.message;
                            reject(new Error(resp?.data?.message));
                        });
                    }
                })
                .catch((error) => {
                    runInAction(() => {
                        this.OrderLoader = false;
                        this.getProductList(Order_id);
                        this.savedfun = true;

                        if (!error.response) {
                            this.errLoadOrder = error?.message;
                            toast.error(error?.message);
                            this.setDuplicateProduct(false);
                            reject(error);
                        } else {
                            this.errLoadOrder = error?.response?.data?.message;
                            this.setDuplicateProduct(false);
                            toast.error(error?.response?.data?.message);
                            reject(new Error(error?.response?.data?.message));
                        }
                    });
                });
        });
    };

    deleteProductOrder = (recordid, Orderid) => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.OrderLoader = true;
            this.Productlist = [];

            this.errLoadOrder = "";
        });
        postMethodData(`/api/v1/admin/Orders/delete-Order-product`, {

            "record_id": JSON.parse(recordid),

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadOrder = false;
                    this.setdelateLoader(true);
                    this.OrderLoader = true;
                    this.Productlist = [];
                    this.errLoadOrder = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadOrder = false;
                    this.setdelateLoader(false);
                    this.OrderLoader = false;
                    this.errLoadOrder = resp?.data?.message;
                    this.getProductList(Orderid);

                    this.Orderid = [];

                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadOrder = false;
                this.errLoadOrder = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadOrder = false;
                        this.OrderLoader = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadOrder = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadOrder = false;
                    this.errLoadOrder = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
}




export const OrderStoreContext = createContext(new OrderStore());
export const useOrderStore = () => useContext(OrderStoreContext);
